import React from 'react';
import { Box, Fade, Grid } from '@mui/material';
import Navbar from '../../Navbar/Navbar';
import LeftDrawer from '../../Dashboard/LeftDrawer/LeftDrawer';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isMoodboardRoute } from '../Moodboard/utils';
import TutoModal from '../Tuto/Tuto';

const DashboardLayout: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        <Navbar />
        <LeftDrawer />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            // flex: isMoodboardRoute(pathname) ? 1 : 'unset',
            // flex: 1,
            p: isMoodboardRoute(pathname) ? 0 : '1rem 4rem',
            width: 'min-content',
            minHeight: 'calc(100vh - 75px)',
            height: { sm: 'auto', md: 'calc(100vh - 75px)' },
            overflow: 'auto',
            maxWidth: isMoodboardRoute(pathname)
              ? '100%'
              : 'min(1500px, calc(100vw - 70px))',
            margin: isMoodboardRoute(pathname) ? 0 : '0 auto',
          }}
        >
          <Outlet />
        </Box>
        <TutoModal />
      </Grid>
    </Fade>
  );
};

export default DashboardLayout;
