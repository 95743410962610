import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'assets/Logo.png';
import Logo_small from 'assets/Logo_small.png';
import Logo_white from 'assets/Logo_white.png';
import CustomButton from 'components/Templates/Buttons/CustomButton';
import NavBarButton from 'components/Templates/Buttons/NavBarButton';
import { Link } from 'react-router-dom';
import { NavItem } from './Navbar.type';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/slices/user/userSlice';
import { Menu, MenuItem, Toolbar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PaymentApi from 'api/payment.api';
import Title from 'components/Templates/Titles/Title';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

interface MenuItemProps {
  name: string;
  path?: string;
  href?: string;
}

const menuItems: MenuItemProps[] = [
  {
    name: 'Comment ça marche ?',
    path: '/#how-it-works',
  },
  {
    name: 'Nos Outils Design',
    path: '/design-tools',
  },
  {
    name: 'Outils À Venir',
    path: '/design-tools/#coming-soon',
  },
  {
    name: 'Tarifs',
    path: '/pricing',
  },
  {
    name: 'Blog',
    href: 'https://blog.molyxa.com',
  },
  // {
  //   name: 'Formation',
  //   href: 'https://formation.molyxa.com',
  // },
];

const navItems: NavItem[] = [
  {
    name: 'Essai Gratuit',
    path: '/register',
  },
  {
    name: 'Se connecter',
    path: '/login',
  },
];

const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useSelector((state: any) => state.userReducer);

  const dispatch = useDispatch();

  const location = useLocation();
  const isPresentationRoute = location.pathname.includes(
    '/dashboard/presentations'
  );
  const isStudio = location.pathname.includes(
    '/dashboard'
  );
  const isMoodboardRoute = location.pathname.includes('/dashboard/creations');

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container = window.document.body;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinkToAccount = React.useCallback(async () => {
    if (!user) return false;
    if (user.role === 'client') {
      return false;
    }

    const stripe_customer_id = user.stripe_customer_id;
    if (!stripe_customer_id) return false;
    const url = await PaymentApi.createPortalSession(stripe_customer_id);
    window.open(url, '_blank')?.focus();
  }, [user]);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        padding: '2rem 0',
      }}
    >
      <Link to="/">
        <img
          src={user ? Logo_small : Logo}
          alt="Logo Molyxa"
          style={{
            maxWidth: '100%',
            width: '85px',
            marginBottom: '1.5rem',
          }}
        />
      </Link>
      <Divider />
      <List
        sx={{
          paddingTop: '1rem',
        }}
      >
        {menuItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <CustomButton
                key={item.name}
                textTransform="none"
                path={item.path}
                href={item.href}
                title={item.name}
                variant={'text'}
                smallPadding
              />
            </ListItemButton>
          </ListItem>
        ))}

        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <CustomButton
                key={item.name}
                textTransform="none"
                path={item.path}
                title={item.name}
                variant={'text'}
                smallPadding
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: { xs: 65, sm: 75 },
        width: '100%',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <AppBar component="nav">
        <Toolbar
          sx={{
            minHeight: { xs: 65, sm: isStudio ? 75 : 90 },
            padding: '0 1.5rem !important',
            backgroundColor: isStudio ? 'white' : 'primary.main',
          }}
        >
          <Link to="/">
            <img
              src={(user && isStudio) ? Logo_small : Logo_white}
              alt="Logo Molyxa"
              style={{
                maxWidth: '100%',
                width: user ? '50px' : '125px',
              }}
            />
          </Link>
          {(isPresentationRoute || isMoodboardRoute) && (
            <Title
              title={isPresentationRoute ? 'Présentation' : 'Création'}
              sx={{
                marginLeft: 11,
                color: 'black',
                textTransform: 'capitalize',
              }}
            />
          )}
          <Box
            sx={{
              display: { xs: user ? 'flex' : 'none', sm: 'flex' },
              marginLeft: 'auto',
              gap: '1.5rem',
            }}
          >
            {!user && (
              <>
                {menuItems.map((item) => (
                  <NavBarButton
                    key={item.name}
                    path={item.path}
                    href={item.href}
                    title={item.name}
                    variant="text"
                    textTransform="none"
                    smallPadding
                  />
                ))}
              </>
            )}
            {user ? (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="primary"
                  sx={{
                    padding: '.5rem',
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: { xs: '2.5rem', md: '2rem' },
                      color: isStudio ? "primary" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    '& .MuiButtonBase-root': {
                      fontSize: '.9rem',
                      fontWeight: 500,
                    },
                  }}
                >
                  {user.stripe_customer_id && (
                    <MenuItem
                      onClick={() => {
                        getLinkToAccount();
                      }}
                    >
                      Mon compte
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      dispatch(logout());
                      handleClose();
                    }}
                  >
                    Se déconnecter
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              navItems.map((item) => (
                <NavBarButton
                  key={item.name}
                  path={item.path}
                  title={item.name}
                  variant={item.type}
                  smallPadding
                  textTransform="none"
                  white
                />
              ))
            )}
          </Box>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              ml: 'auto',
              display: { xs: user ? 'none' : 'inherit', sm: 'none' },
            }}
          >
            <MenuIcon
              sx={{
                fontSize: { xs: '2.25rem', sm: '2rem' },
              }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Navbar;
